import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { fetchData } from '../utils/apiUtils';
import { MainScore, Indicator, Loading } from './common/index';
import DrillDownModal from './DrillDownModal';


function Dashboard({ settings }) {
  const { id } = useParams();
  const [dashboard, setDashboard] = useState([]);
  const [isVisible, setIsVisible] = useState(true); // for tracking
  const [data, setData] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const filters = searchParams.get('filter')?.split(',') || [];
  let refreshInterval;

  const startRefreshInterval = () => {
   refreshInterval = setInterval(fetchAll, 60000);
  };

  const stopRefreshInterval = () => {
   clearInterval(refreshInterval);
  };

  const fetchAll = async () => {
   if (settings.apiUrl !== null) {
     const dashboardData = await fetchData('/Reports.JSON')
     if (JSON.stringify(dashboardData.data) !== JSON.stringify(dashboard)){
        if(!dashboardData || !dashboardData.data) return
        setDashboard(dashboardData.data)
     }
   }
  };  

  useEffect(() => {
   fetchAll();

   if (isVisible) {
     startRefreshInterval();
   }

   return () => {
     stopRefreshInterval();
   };
  }, [settings.apiUrl, dashboard]);
 
  const DWidget = ({ item }) => {
    let {title, goTo} = item;
    let indicators = item.indicators;
    let level = 0;

    const mainScoreMap = {0: "Status: Excellent", 1: "Status: Fair", 2: "Status: Critical"}
    indicators.forEach(indicator => {
      if(indicator.risk > level) level = indicator.risk
    })
    
    return (
      <div className="col-12 col-md-6 col-xl-4 p-2 pb-3 pt-3 h-100">
        <div className="card h-100">
          <div className="card-body d-flex flex-column">
            <h4 className="card-title text-glow">{title}</h4>
            <br /><br />
            <div className="flex-grow-1 d-flex justify-content-center align-items-center">
              <div className="position-relative h-100 w-100">
                <MainScore item={item} goTo={item.goTo} layoutClass={"dashboard-score"}/>
                <div className="indicators-screen">
                  {indicators.map((indicator, index) => {
                    return <Indicator key={`di-${index}-${goTo}`} indicator={indicator} goTo={goTo} index={index} layoutClass={"dashboard-layout"} />
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  let toRender = [];
  
  if(dashboard.length > 0){
    toRender = [
      <DWidget item={dashboard[0]} key="dashboard-widget-1" />,
      <DWidget item={dashboard[1]} key="dashboard-widget-2" />,
      <DWidget item={dashboard[2]} key="dashboard-widget-3" />
    ]
  }

  const getSelectedItem = () => {
    console.log('id is ' + id)
    return dashboard.find(item => item.goTo === id) || dashboard[0] || {};
  };

  console.log(filters)
  if (toRender.length > 0) {
    return (
      <div className="container-fluid h-100">
        <div className="row h-100">
          {toRender}
        </div>
        <DrillDownModal
          show={!!id}
          onHide={() => navigate(`/reports`)}
          item={getSelectedItem()}
          filters={filters}
        />
      </div>
    );
  } else {
    return ( <div className="container-fluid h-100">
      <Loading />
    </div> );
  }
}

export default Dashboard;
