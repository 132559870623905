import React from 'react';

import { useNavigate } from 'react-router-dom';
import { Icon } from './index';
import TrafficSignal from 'react-trafficsignal';
import { Health, Security, Optimization } from './index';

const statusMap = {
  2: "RBB",
  1: "BAB",
  0: "BBG",
};

export const Indicator = ({indicator, goTo, index, layoutClass}) => {
    const navigate = useNavigate();
    const handleClick = (next) => {
        navigate("/reports/" + next);
    };

    let deeplink = indicator.goTo ? goTo + "?filter=" + indicator.goTo : goTo;
    return (
        <div className={`mb-3 indicator-${index} ${layoutClass}`}>
            <div className="screen-wrapper" onClick={() => handleClick(deeplink)}>
                <div className="screen-content align-items-center">
                <div className="w-75 float-start p-1 ps-2 pe-0">
                    <Icon name={indicator.icon} className="me-1" /><br />{indicator.label}
                </div>
                <div className="w-25 float-end position-absolute top-0 end-0" style={{marginTop: "-1.25px"}}>
                    <TrafficSignal status={statusMap[indicator.risk]} />
                </div>
                </div>
            </div>
        </div>
    );
}

export const MainScore = ({item, goTo, layoutClass}) => {
    const navigate = useNavigate();
    const handleClick = (next) => {
        navigate("/reports/" + next);
    };
    const AnimationMap = {
        Health, 
        Security,
        Optimization
      }
    
    let indicators = item.indicators;
    let level = 0;
    
    indicators.forEach(indicator => {
      if(indicator.risk > level) level = indicator.risk
    })
    const mainScoreMap = {0: "Status: Excellent", 1: "Status: Fair", 2: "Status: Critical"}
    const AnimationComponent = item.animation ? AnimationMap[item.animation] || null : null;
    return <div className={`${layoutClass}`}>
        <div onClick={() => handleClick(goTo)} className="screen-large screen-wrapper">
          <div className="screen-content d-flex align-items-center text-center fs-5">
                <div className="w-75 float-start ps-1">
                    {item.icon &&
                        <>
                            <Icon name={item.icon} /> &nbsp;<br />
                        </>
                    }
                    {item.title + " " + mainScoreMap[level]}
                </div>
              <div className="w-25 float-end position-absolute top-0 end-0" style={{marginTop: "-2.5px"}} >
                <TrafficSignal status={statusMap[level]} />
              </div>
          </div>
        </div>
      </div>
  }


