import React, { useState, useEffect } from 'react';
import { Modal, Badge } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchData } from '../utils/apiUtils';
import { DrillDownCharts } from './index'
import { Optimization } from './common/index'

function FetchAndRenderItem({fileName}){
  const [item, setItemData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const res = await fetchData(`/DashboardData/${fileName}`);
        const response = res.data;
        let data;
        if (typeof response === 'string') {
          try {
            const validJsonString = response 
            data = JSON.parse(validJsonString);
          } catch (e) {
            console.error(`Error parsing JSON for ${fileName}:`, e);
            data = null;
            setError(true);
            return
          }
        } else {
          data = response;
        }
        setItemData({ fileName, data });
        setError(false);
      } catch (e) {
        console.log("error loading" + fileName);
        setError(true);
        return
      }
      setLoading(false);
    };

    fetchItemData();
  }, [fileName]);

  if(error){
    console.log("Dashboard: Could not load " + fileName + "... skipping Widget.")
    return
  }

  return (    <div className="p-3 overflow-hidden" style={{ height: '97%' }}>
    {loading ? <div className="m-5 text-center">
      <Optimization level={0} size="small" text="Loading..." /><br /><h3>Loading...</h3>
      </div> : <DrillDownCharts data={item.data} fileName={fileName} />}
  </div>)
}

function DetailFilter({ item, show, onHide }) {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const filters = searchParams.get('filter')?.split(',') || [];

  const toggleIndicator = (indicatorGoTo) => {
    const newFilters = filters.includes(indicatorGoTo)
      ? filters.filter(f => f !== indicatorGoTo)
      : [...filters, indicatorGoTo];
    
    const newSearchParams = new URLSearchParams(searchParams);
    if (newFilters.length > 0) {
      newSearchParams.set('filter', newFilters.join(','));
    } else {
      newSearchParams.delete('filter');
    }
    
    navigate(`${location.pathname}?${newSearchParams}`, { replace: true });
  };

  const renderFiles = [...new Set(
    filters.length > 0
      ? item.indicators
          .filter(indicator => filters.includes(indicator.goTo))
          .flatMap(indicator => indicator.detailFiles)
      : item.indicators.flatMap(indicator => indicator.detailFiles)
  )];
  // const renderFiles = ["Applicative_users.JSON"]

  return (
    <Modal show={show} onHide={onHide} size="xl" aria-labelledby="sh-contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="sh-contained-modal-title-vcenter">
          {item.title} 
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          {item.indicators.map((indicator) => (
            <Badge
              key={indicator.goTo}
              bg={filters.includes(indicator.goTo) ? "primary" : "secondary"}
              className="cursor-pointer m-1"
              onClick={() => toggleIndicator(indicator.goTo)}
            >
              {indicator.label}
              {filters.includes(indicator.goTo) && 
                <span onClick={(e) => { e.stopPropagation(); toggleIndicator(indicator.goTo); }}> &times;</span>
              }
            </Badge>
          ))}
        </div>
        {renderFiles.map((fileName, index) => 
          <FetchAndRenderItem key={`detail-filter-${index}`} fileName={fileName} />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default DetailFilter;