import React, { createContext, useState, useEffect, useContext } from 'react';
import { Lightning, Stars } from 'react-bootstrap-icons';
import { Form } from 'react-bootstrap';

export const ThemeContext = createContext();

let currentTheme = 'dark'; // Default theme

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    currentTheme = savedTheme || 'dark';
    // return currentTheme;
    return 'dark'
  });

  useEffect(() => {
    document.body.className = `theme-${theme}`;
    localStorage.setItem('theme', theme);
    document.documentElement.setAttribute('data-bs-theme', theme);
    currentTheme = theme;
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const ThemeToggle = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  const handleChange = (e) => {
    setTheme(e.target.checked ? 'dark' : 'light');
  };

  return (
    <Form>
      <Form.Check 
        type="switch"
        id="theme-switch"
        label={
          <div className="d-flex align-items-center">
            {theme === 'dark' ? <Stars size={20} /> : <Lightning size={20} />}
            <span className="ms-2">{theme === 'dark' ? 'Fun' : 'Crisp'}</span>
          </div>
        }
        checked={theme === 'dark'}
        onChange={handleChange}
      />
    </Form>
  );
};

// Custom hook for accessing theme within components
export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

// Utility functions that don't use hooks
export const getTheme = () => currentTheme;
