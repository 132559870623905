import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {Icon} from './common/index'; // Make sure to install this package
import { colors } from '../styles/colors';

const ActivityItem = ({ activity, categoryDefinition }) => {
  let color = colors.danger;
  if(activity.Impact_Level < 0.67) color = colors.warning;
  if(activity.Impact_Level < 0.34) color = colors.success;
  return (
    <div className="activity-item" style={{
      width: '180px',
      height: '140px',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      borderLeft: `4px solid ${color}`,
      borderRadius: '4px',
      padding: '10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      boxShadow: `0 0 10px ${color}40`
    }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
        <Icon name={categoryDefinition.icon} style={{ color: color, marginRight: '5px' }} />
        <span style={{ color: '#fff', fontSize: '0.8em', fontWeight: 'bold' }}>{activity.Category}</span>
      </div>
      <div style={{ color: '#fff', fontSize: '0.9em', fontWeight: 'bold', marginBottom: '5px' }}>{activity.Name}</div>
      <div style={{ color: '#adb5bd', fontSize: '0.8em', overflow: 'visible', textOverflow: 'ellipsis' }}>{activity.Description}</div>
    </div>
  );
};

const ActivityThread = ({ thread, categoryDefinitions }) => {
  const [activities, setActivities] = useState([]);
  const [animatingIndex, setAnimatingIndex] = useState(null);

  const itemWidth = 180; 
  const gap = 16; 

  useEffect(() => {
    const addNextActivity = (index) => {
      if (index < thread.length) {
        setTimeout(() => {
          setActivities(prev => [...prev, thread[index]]);
          setAnimatingIndex(index);
        }, 1000 * index); 
      }
    };

    thread.forEach((_, index) => addNextActivity(index));
  }, [thread]);

  const getItemStyle = (index) => {
    const baseStyle = {
      position: "absolute",
      transition: "right 0.5s ease-out",
    };

    const totalItems = activities.length;
    const basePosition = `calc(33.33% - 180px)`;

    if (index === totalItems - 1) {
      return {
        ...baseStyle,
        right: basePosition,
      };
    } else {
      const offset = (totalItems - index - 1) * (itemWidth + gap);
      return {
        ...baseStyle,
        right: `calc(${basePosition} + ${offset}px)`,
      };
    }
  };

  return (
    <div className="card w-100 p-1 overflow-hidden" style={{ height: "150px", position: "relative" }}>
      {activities.map((activity, index) => (
        <div
          key={index}
          className={`${index === animatingIndex ? 'animate__animated animate__slideInRight' : ''}`}
          style={getItemStyle(index)}
        >
          <ActivityItem 
            activity={activity} 
            categoryDefinition={categoryDefinitions[activity.Category]}
          />
        </div>
      ))}
      <div 
        className="time-indicator"
        style={{
          position: 'absolute',
          top: 0,
          left: '66.67%',
          width: '4px',
          height: '100%',
          background: 'linear-gradient(to bottom, rgba(173, 216, 230, 0) 0%, rgba(173, 216, 230, 1) 10%, rgba(173, 216, 230, 1) 90%, rgba(173, 216, 230, 0) 100%)',
          boxShadow: '0 0 8px rgba(173, 216, 230, 0.8)',
          zIndex: 10,
          animation: 'pulse 0.5s infinite ease-in-out',
        }}
      />
      <div 
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '33.33%',
          height: '100%',
          background: 'linear-gradient(to right, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%)',
          pointerEvents: 'none',
        }}
      />
    </div>
  );
};

const Activity = () => {
  const [activityThreads, setActivityThreads] = useState({});
  const [categoryDefinitions, setCategoryDefinitions] = useState({});
  const [error, setError] = useState(null);

  const validateData = (data) => {
    console.log(data)
    if (!Array.isArray(data.activityThreads)) {
      throw new Error("Data is not in the expected format: activityThreads should be an array");
    }

    if (typeof data.categoryDefinitions !== 'object') {
      throw new Error("Data is not in the expected format: categoryDefinitions should be an object");
    }

    data.activityThreads.forEach((thread, index) => {
      if (typeof thread.Id !== 'string' || !thread.Id) {
        throw new Error(`Invalid Id in thread at index ${index}`);
      }

      if (!Array.isArray(thread.activities)) {
        throw new Error(`Activities should be an array in thread ${thread.threadId}`);
      }

      thread.activities.forEach((activity, actIndex) => {
        const requiredFields = ['Id', 'Name', 'Description', 'Category', 'Impact_Level'];
        requiredFields.forEach(field => {
          if (typeof activity[field] !== 'string' || !activity[field]) {
            throw new Error(`Missing or invalid ${field} in activity at index ${actIndex} of thread ${thread.threadId}`);
          }
        });
      });
    });

    return true;
  };

  const fetchData = async () => {
    try {
      const response = await axios.get('/Activity.JSON');
      const newData = response.data;

      try {
        validateData(newData);
      } catch (validationError) {
        console.error('Data validation failed:', validationError.message);
        setError(`Data validation failed: ${validationError.message}`);
        return;
      }

      setActivityThreads(prevThreads => {
        const updatedThreads = { ...prevThreads };

        newData.activityThreads.forEach(thread => {
          if (!updatedThreads[thread.Id]) {
            updatedThreads[thread.Id] = [];
          }

          thread.activities.forEach(activity => {
            const activityExists = updatedThreads[thread.Id].some(
              existingActivity => existingActivity.Id === activity.Id
            );

            if (!activityExists) {
              updatedThreads[thread.Id].push(activity);
            }
          });
        });

        return updatedThreads;
      });

      setCategoryDefinitions(newData.categoryDefinitions);
      setError(null);
    } catch (error) {
      console.error('Error fetching activity threads:', error);
      setError('Failed to fetch activity data');
    }
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(fetchData, 10000);
    return () => clearInterval(interval);
  }, []);

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="container-fluid h-100">
      <div className="row h-100">
        <div className="col-12 p-2 pb-3 pt-3 h-100">
          {Object.entries(activityThreads).map(([threadId, activities]) => (
            <ActivityThread key={threadId} thread={activities} categoryDefinitions={categoryDefinitions} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Activity;